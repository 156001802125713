import { cx } from "../classnames";
import { Check } from "@withjuly/julycons/bold";

interface CheckboxProps {
	label?: string;
	size?: "sm" | "md";
	isChecked: boolean;
	onChecked: (isChecked: boolean) => void;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
	label = "",
	size = "sm",
	isChecked,
	onChecked,
	onClick,
}) => {
	return (
		<div className="flex items-center gap-3">
			<button
				onClick={(e) => {
					onClick?.(e);
					onChecked(!isChecked);
				}}
				className={cx(
					"border-stroke-primary rounded-solis-sm font-repro flex h-4 w-4 items-center justify-center border transition-colors",
					size === "md" && "h-5 w-5",
					isChecked && "border-brand bg-brand",
				)}
				role="checkbox"
			>
				{isChecked ? (
					<Check
						color="#000000"
						width={size === "md" ? "12px" : "10px"}
						height={size === "md" ? "12px" : "10px"}
					/>
				) : null}
			</button>
			<p
				className={cx(
					"text-paragraph-sm text-white-100 font-repro",
					size === "md" && "text-paragraph-md",
					label === "" && "hidden",
				)}
			>
				{label}
			</p>
		</div>
	);
};
