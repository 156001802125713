import z from "zod";
import { DefaultTemplateSchema } from "./template";
import { AgencyFastPayBankDetails } from "./agency-payments";
import { AgencyFastPayStatus } from "./agency-invoices";

const emptyStringToUndefined = z.literal("").transform(() => undefined);

export function asOptionalField<T extends z.ZodTypeAny>(schema: T) {
	return schema.optional().or(emptyStringToUndefined);
}

export const CampaignDisabledReasonSchema = z.enum([
	"instagram",
	"email",
	"no good emails",
]);
export type CampaignDisabledReason = z.infer<
	typeof CampaignDisabledReasonSchema
>;

export const CampaignReviewNameSchema = z.enum([
	"enter details",
	"enter email",
	"finalize email",
	"also working withs",
	"content review",
	"final review",
]);
export type CampaignReviewName = z.infer<typeof CampaignReviewNameSchema>;

export const CampaignReviewStatusSchema = z.enum([
	"incomplete",
	"not viable",
	"no more",
	"complete",
	"sent back",
]);
export type CampaignReviewStatus = z.infer<typeof CampaignReviewStatusSchema>;

export const AdminCampaignStatusUpdate = z.object({
	name: CampaignReviewNameSchema,
	status: CampaignReviewStatusSchema,
	comment: z.string().nullish(),
});

export const CampaignMetadataSchema = z.object({
	paid: z.boolean().optional(),
	gifting: z.boolean().optional(),
	affiliate: z.boolean().optional(),
	affiliateUrl: z.string().optional(),
	companySize: z
		.enum(["micro", "small", "medium", "large", "unknown"])
		.nullish(),
	brandWithBudget: z.boolean().optional(),
});
export type CampaignMetadata = z.infer<typeof CampaignMetadataSchema>;

interface CampaignMetadataField {
	displayName: string;
	inputType: "boolean" | "string";
}

interface CampaignRadioMetadataField {
	displayName: string;
	inputType: "radio";
	options: { label: string; value: string }[];
}

export const CampaignMetadataField = {
	paid: {
		displayName: "Paid",
		inputType: "boolean",
	},
	gifting: {
		displayName: "Gifting",
		inputType: "boolean",
	},
	affiliate: {
		displayName: "Affiliate",
		inputType: "boolean",
	},
	affiliateUrl: {
		displayName: "Affiliate URL",
		inputType: "string",
	},
	brandWithBudget: {
		displayName: "Brand with Budget",
		inputType: "boolean",
	},
	companySize: {
		displayName: "Company Size",
		inputType: "radio",
		options: [
			{ label: "Micro", value: "micro" },
			{ label: "Small", value: "small" },
			{ label: "Medium", value: "medium" },
			{ label: "Large", value: "large" },
			{ label: "Unknown", value: undefined },
		],
	},
} as Record<string, CampaignMetadataField | CampaignRadioMetadataField>;

export const AdminUpdateContactSchema = z.object({
	uuid: z.string().optional(),
	email: asOptionalField(z.string().email()),
	alternativeEmails: z.string().nullish(),
	isPartnershipEmail: z.boolean().optional(),
	priority: z.number().optional(),
	comment: z.string().optional(),
});
export type AdminUpdateContact = z.infer<typeof AdminUpdateContactSchema>;

export const AdminUpdateCampaignSchema = z.object({
	id: z.bigint(),
	name: z.string().nullish(),
	lastActive: z.date().nullish(),
	isActive: z.boolean().optional(),
	isGeneral: z.boolean().optional(),
	disabledReasons: z.string().nullish(),
	company: z
		.object({
			id: z.bigint(),
			url: z.string().nullish(),
			name: z.string().optional(),
			description: z.string().nullish(),
			contacts: z.array(AdminUpdateContactSchema).optional(),
			profiles: z
				.array(
					z.object({
						id: z.bigint(),
						username: z.string(),
					}),
				)
				.optional(),
		})
		.optional(),
	matchingPreferences: z
		.object({
			gender: z.string().nullish(),
			industries: z.string().nullish(),
		})
		.nullish(),
	statusUpdates: z.array(AdminCampaignStatusUpdate).optional(),
	metadata: CampaignMetadataSchema.nullish(),
});
export type AdminUpdateCampaign = z.infer<typeof AdminUpdateCampaignSchema>;

export const AdminCampaignEnterDetailsCompleteSchema = z.object({
	id: z.bigint(),
	name: z.string().min(1),
	disabledReasons: z.string().nullish(),
	company: z.object({
		id: z.bigint(),
		url: z.string().min(1),
		description: z.string().min(1),
		profiles: z.array(
			z.object({
				id: z.bigint(),
				username: z.string(),
			}),
		),
	}),
	matchingPreferences: z.object({
		gender: z.string(),
		industries: z.string(),
	}),
});

export const AdminCampaignEnterEmailCompleteSchema = z.object({
	id: z.bigint(),
	company: z.object({
		id: z.bigint(),
		contacts: z
			.array(
				z.object({
					uuid: z.string(),
					email: z.string().email(),
					alternativeEmails: z.string().nullish(),
				}),
			)
			.optional(),
	}),
});

export const AdminCampaignFinalizeEmailCompleteSchema = z.object({
	id: z.bigint(),
	company: z.object({
		id: z.bigint(),
		contacts: z
			.array(
				z.object({
					uuid: z.string(),
					email: z.string().email(),
					alternativeEmails: z.string().nullish(),
					isPartnershipEmail: z.boolean(),
				}),
			)
			.optional(),
	}),
});

export const AdminUpsertAwwSchema = z.object({
	id: z.number().optional(),
	campaignId: z.number(),
	approved: z.boolean().optional(),
	platform: z.string(),
	postUrl: z.string().url().optional(),
	postedAt: z.date().optional(),
	username: z.string().optional(),
	caption: z.string().optional(),
	matchingPreferencesSnapshot: z.object({
		size: z.number(),
		engagement: z.number(),
	}),
});

export type AdminAction =
	| "CampaignManagement"
	| "WishlistFulfillment"
	| "UserManagement"
	| "SignInAs"
	| "BanUser"
	| "InvoiceManagement"
	| "TemplateManagement"
	| "ReferralManagement"
	| "PromptManagement"
	| "J4bManagement"
	| "AgencyPayments";

/**
 * Fields to include in a search query
 *
 * This translates to an include clause in a prisma query.
 */
export interface SearchInclude {
	[key: string]: SearchInclude | boolean;
}

/**
 * Schema to handle includes in a search query
 *
 * This is different from typical input schemas as it's structure is recursive.
 */
export const SearchIncludeSchema: z.ZodType<SearchInclude> = z.lazy(() =>
	z.record(
		z
			.object({
				include: SearchIncludeSchema,
			})
			.or(z.boolean()),
	),
);

export const InitializeCampaign = z.object({
	companyName: z.string(),
	companyHandle: z.string(),
	campaignName: z.string(),
	wishlistRequestId: z.number().optional(),
});

export const CsvCampaign = InitializeCampaign.extend({
	email: z.string().optional(),
});

export const AdminUpsertDefaultTemplateSchema = DefaultTemplateSchema.extend({
	id: z.bigint().optional(),
	body: z.string(),
	subject: z.string(),
	isAvailableInFreeTier: z.boolean(),
}).omit({ requiresUpgrade: true, attachments: true });

export const AdminAgencySchema = z.object({
	name: z.string(),
	email: z.string().email(),
	uuid: z.string().uuid(),
	numCreators: z.number(),
	numManagers: z.number(),
	billing: z.enum(["legacy", "started", "free trial"]),
	ownerId: z.bigint().optional(),
});
export type AdminAgency = z.infer<typeof AdminAgencySchema>;

export const AdminAgenciesSchema = AdminAgencySchema.array();
export type AdminAgencies = z.infer<typeof AdminAgenciesSchema>;

export const AgencyThemeUpsertFormSchema = z.object({
	uuid: z.string().uuid().optional(),
	agencyUuid: z.string().uuid().optional(),
	name: z.string(),
	textPrimary: z.string().min(6),
	textSecondary: z.string().min(6),
	textHeader: z.string().min(6),
	textButton: z.string().min(6),
	background: z.string().min(6),
	fill: z.string().min(6),
	graphPrimary: z.string().min(6),
	graphSecondary: z.string().min(6),
	stroke: z.string().min(6),
	buttonFill: z.string().min(6),
	buttonFillSecondary: z.string().min(6),
	saveAndSet: z.boolean().optional(),
});
export type AgencyThemeUpsertForm = z.infer<typeof AgencyThemeUpsertFormSchema>;

export const AgencyThemePreviewSchema = z.object({});
export type AgencyThemePreviewSchema = z.infer<typeof AgencyThemePreviewSchema>;

export const AgencyForThemeEditorSchema = z.object({
	uuid: z.string(),
	name: z.string(),
});
export type AgencyForThemeEditor = z.infer<typeof AgencyForThemeEditorSchema>;

export const AgencyPaymentsSimulationSchema = z.object({
	agencyPaymentsUuid: z.string().uuid(),
	amount: z.number(),
	network: z.enum(["ach", "wire"]),
});
export type AgencyPaymentsSimulation = z.infer<
	typeof AgencyPaymentsSimulationSchema
>;

export const AgencyOverviewSchema = z.object({
	profile: z.object({
		uuid: z.string(),
		talent: z
			.object({
				uuid: z.string().uuid(),
				name: z.string(),
				email: z.string(),
			})
			.array(),
		numTalent: z.number(),
		numManagers: z.number(),
		isPaymentsEnabled: z.boolean(),
		name: z.string(),
	}),
	billing: z.object({
		nextChargeDate: z.date().nullable(),
		legacyBilling: z.boolean(),
		card: z
			.object({
				lastFour: z.string(),
				expiration: z.string(),
			})
			.nullable(),
		invoices: z
			.object({
				paidAt: z.date(),
				status: z.string(),
				total: z.number(),
				url: z.string().url().nullish(),
			})
			.array(),
		status: z.string(),
	}),
	payments: z.object({
		uuid: z.string().uuid().optional(),
		fastPayBankDetails: AgencyFastPayBankDetails.optional(),
		nextPayoutDate: z.date(),
		total: z.number(),
		nextPayoutAmount: z.number(),
		applicationStatus: z.string(),
		incomingPayments: z
			.object({
				receivedAt: z.date(),
				amount: z.number(),
				status: z.string(),
				uuid: z.string().uuid(),
			})
			.array(),
		invoices: z
			.object({
				uuid: z.string().uuid(),
				createdAt: z.date(),
				dueDate: z.date(),
				amount: z.number(),
				brandName: z.string(),
				status: z.enum(["PAID", "SENT", "VOID"]),
				fastPayStatus: AgencyFastPayStatus.optional(),
			})
			.array(),
	}),
});
export type AgencyOverview = z.infer<typeof AgencyOverviewSchema>;

export const TalentOverview = z.object({
	stories: z
		.object({
			mediaUrl: z.string().url().optional(),
			createdAt: z.date(),
		})
		.array(),
});
export type TalentOverview = z.infer<typeof TalentOverview>;
