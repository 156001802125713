import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { JSXElementConstructor, PropsWithChildren } from "react";
import { IconButton } from "./IconButton";
import { CaretDown, DotsThree } from "@withjuly/julycons/bold";
import { Button } from "./Button";
import { cx } from "../classnames";
import React from "react";

export interface RootProps {
	isOpen?: boolean;
	onOpenChange?: (isOpen: boolean) => void;
}

export const Root: React.FC<PropsWithChildren<RootProps>> = ({
	isOpen,
	onOpenChange,
	children,
}) => {
	return (
		<DropdownMenu.Root open={isOpen} onOpenChange={onOpenChange}>
			{children}
		</DropdownMenu.Root>
	);
};

interface BaseTriggerProps {
	size?: "sm" | "md";
}

interface IconTriggerProps extends BaseTriggerProps {
	variant?: "icon";
	icon?: JSXElementConstructor<{ className?: string }>;
}

interface ButtonTriggerProps extends BaseTriggerProps {
	variant: "button";
	label: string;
}

interface CustomTriggerProps extends BaseTriggerProps {
	variant: "custom";
}

type TriggerProps =
	| IconTriggerProps
	| ButtonTriggerProps
	| PropsWithChildren<CustomTriggerProps>;

export const Trigger: React.FC<TriggerProps> = (props) => {
	const { size = "md" } = props;

	if (props.variant === "icon" || props.variant === undefined) {
		const Icon = props.icon ?? DotsThree;
		return (
			<DropdownMenu.Trigger
				className="transition-transform focus:outline-none focus:ring-0"
				asChild={true}
			>
				<IconButton size={size} icon={Icon} variant="blank-primary" />
			</DropdownMenu.Trigger>
		);
	} else if (props.variant === "button") {
		return (
			<DropdownMenu.Trigger className="group transition-transform focus:outline-none focus:ring-0">
				<Button
					size={size}
					trailingIcon={(className) => (
						<CaretDown
							className={cx(
								"transform transition-all group-data-[state=open]:rotate-180",
								className,
							)}
						/>
					)}
				>
					{props.label}
				</Button>
			</DropdownMenu.Trigger>
		);
	} else if (props.variant == "custom") {
		return <DropdownMenu.Trigger>{props.children}</DropdownMenu.Trigger>;
	} else {
		return null;
	}
};

export const Content: React.FC<DropdownMenu.DropdownMenuContentProps> = ({
	children,
	...rest
}) => {
	return (
		<DropdownMenu.Portal>
			<DropdownMenu.Content
				className="bg-surface-primary rounded-solis-lg border-stroke-primary divide-stroke-tertiary z-[200] translate-y-2 transform divide-y-[0.5px] overflow-clip border"
				align="end"
				{...rest}
			>
				{children}
			</DropdownMenu.Content>
		</DropdownMenu.Portal>
	);
};

interface ItemProps extends DropdownMenu.DropdownMenuItemProps {
	variant?: "default" | "danger";
	icon?: JSXElementConstructor<{ className?: string }>;
	disabled?: boolean;
}

export const Item: React.FC<PropsWithChildren<ItemProps>> = ({
	variant = "default",
	icon: Icon,
	disabled,
	children,
	className,
	...rest
}) => {
	return (
		<DropdownMenu.Item
			disabled={disabled}
			className={cx(
				"flex h-10 min-w-[188px] cursor-pointer items-center gap-2 px-3 focus:outline-none focus:ring-0 disabled:opacity-40",
				variant === "default" &&
					"text-text-primary hover:bg-surface-hover-1 focus:bg-surface-hover-1",
				variant === "danger" &&
					"text-red-7 hover:bg-surface-error focus:bg-surface-error",
				disabled && "cursor-not-allowed opacity-40",
				className,
			)}
			{...rest}
		>
			{Icon ? <Icon className="h-4 w-4" /> : null}
			<p className="text-paragraph-md font-repro text-inherit">{children}</p>
		</DropdownMenu.Item>
	);
};
