import { createStore } from "zustand/vanilla";
import { immer } from "zustand/middleware/immer";
import {
	AccentColorSchema,
	AgencyEditorRoster,
	AllThemesSchema,
	RosterEditorCreator,
	RosterOrderBySchema,
} from "@withjuly/fabric";

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type RosterState = {
	roster: AgencyEditorRoster;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type RosterActions = {
	// Set Roster
	setRoster: (roster: AgencyEditorRoster) => void;

	// Set options
	setShowFollowers: (show: boolean) => void;
	setTitle: (title: string) => void;
	setDescription: (description: string) => void;
	setAllowFilterByMediaKitTags: (show: boolean) => void;
	setShowMediaKitTags: (show: boolean) => void;
	setShowPendingCreators: (show: boolean) => void;
	setOrder: (orderBy: "NAME" | "AUDIENCE" | "MANUAL") => void;
	setCreators: (creators: RosterEditorCreator[]) => void;
	setShowNonExclusiveCreators: (show: boolean) => void;
	setCreatorVisibility: (uuid: string) => void;
};

export type RosterStore = RosterState & RosterActions;

const defaultRosterState = {
	roster: {
		uuid: "",
		name: "",
		title: "",
		description: "",
		email: "",
		logoUrl: "",
		theme: AllThemesSchema.Enum.july,
		accentColor: AccentColorSchema.Enum.light,
		creators: [],
		allCreators: [],
		tags: [],
		showCreatorTags: false,
		filterByCreatorTags: false,
		isPrivate: false,
		customTheme: null,
		rosterOrderBy: RosterOrderBySchema.Enum.NAME,
		logoBase64: "",
		creatorProfilePicturesBase64s: {},
		showFollowers: true,
		showPendingCreators: false,
		showNonExclusiveCreators: true,
	},
};

export const createRosterStore = (
	initState: RosterState = defaultRosterState,
) => {
	return createStore<RosterStore>()(
		immer((set) => ({
			...initState,
			setRoster: (roster) => {
				set((state) => {
					state.roster = roster;
				});
			},
			setShowMediaKitTags: (show) => {
				set((state) => {
					state.roster.showCreatorTags = show;
				});
			},
			setTitle: (title) => {
				set((state) => {
					state.roster.title = title;
				});
			},
			setAllowFilterByMediaKitTags: (show) => {
				set((state) => {
					state.roster.filterByCreatorTags = show;
				});
			},
			setOrder: (order) => {
				set((state) => {
					state.roster.rosterOrderBy = order;
				});
			},
			setShowFollowers: (show) => {
				set((state) => {
					state.roster.showFollowers = show;
				});
			},
			setShowPendingCreators: (show) => {
				set((state) => {
					state.roster.showPendingCreators = show;
					const filteredCreators = [...state.roster.creators];
					if (state.roster.showNonExclusiveCreators === false) {
						filteredCreators.filter(
							(creator) => creator.exclusivity === "exclusive",
						);
					}
					if (show === false) {
						filteredCreators.filter((creator) => creator.isConnected);
					}
					state.roster.creators = filteredCreators;
				});
			},
			setCreators: (creators) => {
				set((state) => {
					state.roster.allCreators = creators;
				});
			},
			setShowNonExclusiveCreators: (show) => {
				set((state) => {
					state.roster.showNonExclusiveCreators = show;
					let filteredCreators = [...state.roster.creators];
					if (show === false) {
						filteredCreators = filteredCreators.filter(
							(creator) => creator.exclusivity === "exclusive",
						);
					}
					if (state.roster.showPendingCreators === false) {
						filteredCreators = filteredCreators.filter(
							(creator) => creator.isConnected,
						);
					}
					state.roster.creators = filteredCreators;
				});
			},
			setDescription: (description) => {
				set((state) => {
					state.roster.description = description;
				});
			},
			setCreatorVisibility: (uuid) => {
				set((state) => {
					state.roster.allCreators = state.roster.allCreators.map((creator) => {
						if (creator.rosterCreatorUuid === uuid) {
							return {
								...creator,
								enabled: !creator.enabled,
							};
						} else {
							return creator;
						}
					});
				});
			},
		})),
	);
};
