import { z } from "zod";
import { UsernameSchema } from "./user";
import {
	CustomProfileStatSchema,
	Platform,
	PlatformStatsSchema,
} from "./profile";
import { ThemeSchema } from "./themes";
import { CreatorCardDisabledStatsEnum } from "./decks";

export const AccentColorSchema = z.enum(["dark", "light"]);
export type AccentColor = z.infer<typeof AccentColorSchema>;
export const CurrencyTypeSchema = z.enum([
	"USD",
	"CAD",
	"GBP",
	"EUR",
	"CHF",
	"JPY",
	"INR",
	"AUD",
	"CNY",
]);

export const DefaultThemesSchema = z.enum([
	"july",
	"green",
	"red",
	"yellow",
	"pink",
	"purple",
	"black",
]);
export const CustomThemesSchema = z.enum([
	"sixteenth",
	"bowie",
	"no name talent",
	"le fizz",
	"next step talent",
	"untitled secret",
	"diner",
	"socalite",
	"creatorcamp",
	"touchpoint",
]);
export const AllThemesSchema = z.enum([
	...DefaultThemesSchema.options,
	...CustomThemesSchema.options,
] as const);
export type AllThemes = z.infer<typeof AllThemesSchema>;
export const RosterOrderBySchema = z.enum(["NAME", "AUDIENCE", "MANUAL"]);
export type RosterOrderBy = z.infer<typeof RosterOrderBySchema>;

export const Exclusivity = z.enum(["exclusive", "non-exclusive"]);
export type Exclusivity = z.infer<typeof Exclusivity>;

export const UpdateAgencySchema = z.object({
	name: z.string().min(1).max(255),
	username: UsernameSchema,
	description: z.string(),
	email: z.string().email(),
	logo: z
		.object({
			uuid: z.string().uuid(),
		})
		.optional(),
	hasCompletedOnboarding: z.boolean(),
	theme: z.string(),
	accentColor: AccentColorSchema,
	currency: CurrencyTypeSchema.optional(),
});

export const UpdateRosterSchema = z.object({
	showMediaKitTags: z.boolean(),
	allowFilterByMediaKitTags: z.boolean(),
	showFollowers: z.boolean(),
	showPendingCreators: z.boolean(),
	showNonExclusiveCreators: z.boolean(),
});
export type UpdateRoster = z.infer<typeof UpdateRosterSchema>;

export const InviteAuthTalentSchema = z.object({
	firstName: z.string().min(2),
	lastName: z.string(),
	displayName: z.string().nullable(),
	username: UsernameSchema,
	email: z.string().email(),
	managerEmail: z.string().email(),
	exclusive: z.boolean(),
	isAuth: z.literal(true),
});

export const AgencyCreatorSignUpSchema = z.object({
	firstName: z.string(),
	lastName: z.string(),
	username: UsernameSchema.refine(
		(value) => !/\s/.test(value),
		"Spaces are not allowed",
	),
	email: z.string().email(),
	agencyUsername: z.string(),
	uuid: z.string().uuid(),
});

export const InviteNoAuthTalentSchema = z.object({
	firstName: z.string().min(2),
	lastName: z.string(),
	displayName: z.string().nullable(),
	username: UsernameSchema,
	platformUsernames: z.object({
		instagramUsername: z.string(),
		youtubeUsername: z.string(),
		tiktokUsername: z.string(),
	}),
	email: z.string().email(),
	managerEmail: z.string().email(),
	exclusive: z.boolean(),
	sendOnboardingSurvey: z.boolean(),
	isAuth: z.literal(false),
});

export const InviteTalentSchema = z.discriminatedUnion("isAuth", [
	InviteAuthTalentSchema,
	InviteNoAuthTalentSchema,
]);

export const ConnectNoAuthAccountSchema = z.object({
	userUuid: z.string(),
	platformUsernames: z
		.object({
			instagramUsername: z.string(),
			youtubeUsername: z.string(),
			tiktokUsername: z.string(),
		})
		.refine(
			({ instagramUsername, youtubeUsername, tiktokUsername }) =>
				instagramUsername !== "" ||
				tiktokUsername !== "" ||
				youtubeUsername !== "",
		),
});

export const UpsertEntrySchema = z.object({
	uuid: z.string().uuid().optional(),
	creator: z.object({
		creatorProfile: z.object({
			uuid: z.string().uuid(),
		}),
	}),
	order: z.number(),
});
export type UpsertEntry = z.infer<typeof UpsertEntrySchema>;

export const UpsertOneSheetSchema = z.object({
	uuid: z.string().uuid().optional(),
	entries: UpsertEntrySchema.array(),
	title: z.string(),
	brandName: z.string().optional(),
	brandLogo: z
		.object({
			uuid: z.string().uuid(),
		})
		.optional(),
	description: z.string(),
	contactEmail: z.string().optional(),
	isPublished: z.boolean(),
	showAbout: z.boolean().optional(),
	showStats: z.boolean().optional(),
	showRates: z.boolean().optional(),
	showPartnerships: z.boolean().optional(),
	showStatsOverview: z.boolean().optional(),
	slug: z.string().nullable(),
});
export type UpsertOneSheet = z.infer<typeof UpsertOneSheetSchema>;

export const FabricCreatorCardRateSchema = z.object({
	title: z.string(),
	price: z.string(),
	uuid: z.string().uuid(),
});
export type FabricCreatorCardRate = z.infer<typeof FabricCreatorCardRateSchema>;

export const FabricCreatorCardRatesSchema = z.array(
	FabricCreatorCardRateSchema,
);

export const UpsertCreatorCardRateSchema = z.object({
	uuid: z.string().uuid(),
	title: z.string().min(1).max(30),
	price: z.string().min(1),
});

export const CreatorCardPostSchema = z.object({
	postUrl: z.string().url(),
	thumbnailUrl: z.string().url(),
	expiresAt: z.number().optional(),
	videoId: z.string().optional(),
});
export type CreatorCardPost = z.infer<typeof CreatorCardPostSchema>;

export const FabricCreatorCardPostSchema = z.object({
	postUrl: z.string().url(),
	thumbnailUrl: z.string().url(),
	thumbnailBase64: z.string().optional(),
});
export type FabricCreatorCardPost = z.infer<typeof FabricCreatorCardPostSchema>;

export const FabricCreatorCardCollabSchema = z.object({
	title: z.string(),
	postUrl: z.string().url().optional(),
	logoUrl: z.string().url().optional(),
	logoBase64: z.string().optional(),
	order: z.number(),
});
export type FabricCreatorCardCollab = z.infer<
	typeof FabricCreatorCardCollabSchema
>;

export const CreatorCardCustomProfileSchema = z.object({
	platform: z.string(),
	stats: z.array(CustomProfileStatSchema).optional(),
	demographics: z.object({
		gender: z
			.object({
				male: z.number(),
				female: z.number(),
			})
			.optional(),
		age: z.record(z.number()).optional(),
	}),
});
export type CreatorCardCustomProfile = z.infer<
	typeof CreatorCardCustomProfileSchema
>;

export const FabricCreatorCardSchema = z.object({
	uuid: z.string(),
	agencyCreatorUuid: z.string().uuid().optional(),
	description: z.string(),
	highlightedPosts: z.array(FabricCreatorCardPostSchema),
	showHighlightedPosts: z.boolean(),
	platformStats: z.array(PlatformStatsSchema),
	creatorCardCustomProfiles: z.array(CreatorCardCustomProfileSchema),
	rates: z.array(FabricCreatorCardRateSchema),
	collabs: z.array(FabricCreatorCardCollabSchema),
	profilePictureUrl: z.string().optional(),
	profilePictureBase64: z.string().optional(),
	firstName: z.string(),
	lastName: z.string(),
	displayName: z.string().optional(),
	username: z.string(),
	creatorProfileUuid: z.string(),
	isShowingDefaultPosts: z.boolean(),
});
export type FabricCreatorCard = z.infer<typeof FabricCreatorCardSchema>;

export const CreatorCardDisabledStatSchema = z.object({
	platform: z.nativeEnum(Platform),
	disabledStats: z.array(CreatorCardDisabledStatsEnum),
	platformProfileUuid: z.string().uuid(),
});

export const CreatorCardDisabledStatsSchema = z.array(
	CreatorCardDisabledStatSchema,
);
export type CreatorCardDisabledStats = z.infer<
	typeof CreatorCardDisabledStatsSchema
>;

export const UpdateCreatorCardFormSchema = z.object({
	description: z.string().optional(),
	coverPhotoUuid: z.string().optional(),
});
export type UpdateCreatorCardFormSchema = z.infer<typeof UpdateAgencySchema>;

export const FabricEntrySchema = z.object({
	uuid: z.string(),
	order: z.number(),
	creator: z.object({
		creatorProfile: z.object({
			uuid: z.string().uuid(),
		}),
	}),
	creatorCard: FabricCreatorCardSchema,
});
export type FabricEntry = z.infer<typeof FabricEntrySchema>;

export const FabricOneSheetSchema = z.object({
	uuid: z.string(),
	createdAt: z.date(),
	updatedAt: z.date(),
	title: z.string(),
	showAbout: z.boolean(),
	showPartnerships: z.boolean(),
	showRates: z.boolean(),
	showStats: z.boolean(),
	slug: z.string().nullable(),
	description: z.string(),
	isPublished: z.boolean(),
	contactEmail: z.string().optional(),
	brandName: z.string().optional(),
	showStatsOverview: z.boolean(),
	entries: z.array(FabricEntrySchema),
	brandLogo: z
		.object({
			uuid: z.string().uuid(),
			url: z.string().url().optional(),
			base64: z.string().optional(),
		})
		.optional(),
	agency: z.object({
		name: z.string(),
		username: z.string(),
		email: z.string().email(),
		theme: z.string(),
		accentColor: z.string(),
		logoUrl: z.string().optional(),
		logoBase64: z.string().optional(),
		currency: z.string(),
	}),
	customTheme: ThemeSchema.nullable(),
});
export type FabricOneSheet = z.infer<typeof FabricOneSheetSchema>;

export const UpdateAgencyCreatorHiddenTagsSchema = z.object({
	creatorProfileUuid: z.string().uuid(),
	tags: z.string(),
});

export const CastingTagFormOptionSchema = z.object({
	uuid: z.string().uuid(),
	label: z.string(),
	value: z.string(),
});

export const CastingTagFormQuestionSchema = z.object({
	uuid: z.string().uuid(),
	question: z.string(),
	options: z.array(CastingTagFormOptionSchema),
});
export type CastingTagFormQuestion = z.infer<
	typeof CastingTagFormQuestionSchema
>;

export const CastingTagFormSchema = z.array(CastingTagFormQuestionSchema);
export type CastingTagForm = z.infer<typeof CastingTagFormSchema>;

export const CastingTagsCreatorFormSchema = z.array(z.string());

export const AgencyProfileSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	updatedAt: z.date(),
	hasCompletedOnboarding: z.boolean(),
	hasCompletedSetUp: z.boolean(),
	hasRequestedCustomTheme: z.boolean(),
	name: z.string(),
	username: z.string(),
	description: z.string(),
	email: z.string().email(),
	theme: z.string(),
	accentColor: AccentColorSchema,
	currency: CurrencyTypeSchema,
	logo: z
		.object({
			uuid: z.string().uuid(),
		})
		.optional(),
	logoUrl: z.string().url().optional(),
	onboardingForm: CastingTagFormSchema.optional(),
	paymentsApplicaionStatus: z.enum([
		"NOT_STARTED",
		"CREATED",
		"PENDING",
		"ACCEPTED",
		"DENIED",
	]),
	numManagers: z.number(),
	isLegacyBilling: z.boolean(),
	members: z
		.object({
			uuid: z.string().uuid(),
			firstName: z.string(),
			lastName: z.string(),
			profilePictureUrl: z.string().url().optional(),
		})
		.array(),
	defaultTalentCommission: z.number(),
	isPaymentsEnabled: z.boolean(),
	contentCategories: z.string().array(),
});
export type AgencyProfile = z.infer<typeof AgencyProfileSchema>;

export const RosterCreatorPlatformSchema = z.object({
	platform: z.nativeEnum(Platform),
	platformUrl: z.string().url(),
	size: z.number(),
});
export type RosterCreatorPlatform = z.infer<typeof RosterCreatorPlatformSchema>;

export const RosterCreatorSchema = z.object({
	uuid: z.string().uuid(),
	name: z.string(),
	profilePictureUrl: z.string().url().optional(),
	tags: z.string().array(),
	industries: z.string().array(),
	username: z.string(),
	isConnected: z.boolean(),
	platforms: z
		.object({
			platform: z.nativeEnum(Platform),
			platformUrl: z.string().url(),
			size: z.number(),
		})
		.array()
		.optional(),
	order: z.number(),
	rosterCreatorUuid: z.string().uuid(),
	exclusivity: Exclusivity,
});
export type RosterCreator = z.infer<typeof RosterCreatorSchema>;

export const RosterEditorCreatorSchema = z.object({
	uuid: z.string().uuid(),
	name: z.string(),
	profilePictureUrl: z.string().url().optional(),
	tags: z.string().array(),
	industries: z.string().array(),
	username: z.string(),
	isConnected: z.boolean(),
	platforms: z
		.object({
			platform: z.nativeEnum(Platform),
			platformUrl: z.string().url(),
			size: z.number(),
		})
		.array()
		.optional(),
	order: z.number(),
	rosterCreatorUuid: z.string().uuid(),
	exclusivity: Exclusivity,
	enabled: z.boolean(),
});
export type RosterEditorCreator = z.infer<typeof RosterEditorCreatorSchema>;

export const AgencyRosterSchema = z.object({
	name: z.string(),
	title: z.string(),
	description: z.string(),
	email: z.string().email(),
	logoUrl: z.string().url().optional(),
	theme: AllThemesSchema,
	accentColor: AccentColorSchema,
	creators: RosterCreatorSchema.array(),
	tags: z.string().array(),
	showCreatorTags: z.boolean(),
	filterByCreatorTags: z.boolean(),
	showFollowers: z.boolean(),
	isPrivate: z.boolean(),
	customTheme: ThemeSchema.nullable(),
	rosterOrderBy: RosterOrderBySchema,
	logoBase64: z.string().nullable(),
	creatorProfilePicturesBase64s: z.record(z.string().nullable()),
});
export type AgencyRoster = z.infer<typeof AgencyRosterSchema>;

export const AgencyEditorRosterSchema = AgencyRosterSchema.extend({
	showPendingCreators: z.boolean(),
	uuid: z.string().uuid(),
	allCreators: RosterEditorCreatorSchema.array(),
	showNonExclusiveCreators: z.boolean(),
});
export type AgencyEditorRoster = z.infer<typeof AgencyEditorRosterSchema>;

export const AddBillingDetailsRequestSchema = z.object({
	expirationYear: z.number(),
	expirationMonth: z.number(),
	cardNumber: z.string(),
	cardCvc: z.string(),
});
export type AddBillingDetailsRequest = z.infer<
	typeof AddBillingDetailsRequestSchema
>;

export const RosterStatsSchema = z.object({
	platform: z.string(),
	stats: z.array(
		z.object({
			name: z.string(),
			value: z.number(),
			tooltip: z.string(),
		}),
	),
});
export type RosterStats = z.infer<typeof RosterStatsSchema>;

export const SearchSchema = z.object({
	tags: z.string().array(),
	platform: z.string().array(),
	engagementRate: z.number(),
	gender: z.enum(["Male", "Female"]).optional(),
	genderPercentage: z.number().nullable(),
	ages: z.string().array(),
	agePercentage: z.number().nullable(),
	locations: z.string().array(),
	audienceLocationPercentage: z.number().nullable(),
	sizes: z
		.enum([
			"isSmallAudience",
			"isMediumAudience",
			"isMediumLargeAudience",
			"isLargeAudience",
			"isHugeAudience",
			"isMassiveAudience",
		])
		.array(),
});

export const ManagerOnboardForm = z.object({
	firstName: z.string().min(1),
	lastName: z.string().min(1),
});

export const AgencyAddCustomThemeSchema = z.object({
	website: z.string().optional(),
	notes: z.string().optional(),
});

export const AddWrittenResponseAnswersSchema = z.array(
	z.object({
		questionUuid: z.string().uuid(),
		answer: z.string(),
	}),
);

export const UpdateWrittenQuestionSchema = z.object({
	oldOrder: z.number(),
	newOrder: z.number(),
	questionUuid: z.string().uuid(),
});

export const UpsertWrittenQuestionSchema = z.object({
	uuid: z.string().uuid(),
	question: z.string(),
	order: z.number(),
});

export const ManagerSchema = z.object({
	uuid: z.string(),
	email: z.string(),
	firstName: z.string(),
	lastName: z.string(),
});
export type Manager = z.infer<typeof ManagerSchema>;

export const GetManagerSchema = z.object({
	creatorUuid: z.string().uuid(),
});
export type GetManager = z.infer<typeof GetManagerSchema>;

export const LinkManagerAndCreatorSchema = z.object({
	memberUuid: z.string().uuid(),
	creatorUuid: z.string().uuid(),
});
export type LinkManagerAndCreator = z.infer<typeof LinkManagerAndCreatorSchema>;
